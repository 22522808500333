
import slidersInit from "../components/sliders.js";
import filtersInit from "../components/filters.js";
import scrollInit from "../components/scroll.js";

document.addEventListener("DOMContentLoaded", () => {

    const { heroSliderInit } = slidersInit();
    heroSliderInit();

    const { filterHomeInit } = filtersInit();
    filterHomeInit();

    const { navScrollInit } = scrollInit();
    navScrollInit();

});
